<template>
  
  <div>
    <b-button v-b-modal.modal-add-product>+ Ajouter un produit au catalogue</b-button><br /><br />
    <vue-good-table
      :columns="columns"
      :rows="brand_models"
      :pagination-options="{
        enabled: false,
        
      }"
      :search-options="{
        enabled: true
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
           
             
               
              <b-dropdown-item v-b-modal.modal-danger @click="selectedItem=props.row.id">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
       
       
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
     
    
    </vue-good-table>

    <b-modal
        id="modal-add-product"
        cancel-variant="outline-secondary"
          cancel-title="Non"
          no-close-on-esc
          no-close-on-backdrop
          
        ok-title="Ajouter le produit"
        @ok="addItem(selectedProduct,price)"
      
        centered
        title="Ajouter un produit au catalogue"
      >
        <b-card-text>
          <b-form
            class="auth-user-form mt-2"
            @submit.prevent
          >
          <b-form-group 
            label="Produit"
            label-for="basicInput"
          >
         
          <v-select
                v-model="selectedProduct"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
              
                :options="productChoices"
              />
          </b-form-group>

          <b-form-group 
            label="Prix"
            label-for="basicInput"
          >
        
          
            <b-form-input
             required
              id="basicInput"
              type="text"
              v-model="price"
             
              placeholder="Prix (€)"
             
            />
           
          </b-form-group>
        </b-form>
        
        </b-card-text>
      </b-modal>

        
      <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deleteItem(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer le produit du catalogue ?
          </b-card-text>
        </b-modal>
  </div>


</template>

<script>
import {BRow,BCol,BModal,BFormFile,BFormCheckbox,BForm, BFormTextarea,BOverlay,BButton, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Auth,Utils} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import UserForm from './UserForm.vue'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
  components: { 
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    UserForm,
    BRow,
    BCol,
    BFormFile,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  data() {
    return {
      user:null,
      loaded:false,
      brand_models:[],
      productChoices:[],
      selectedItem:null,
      loading:true,
      selectedProduct:null,
      price:null,
      columns: [
        {
          label: 'ID',
          field: 'glass.id',
        },
        {
          label: 'Nom',
          field: 'glass.name',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Action',
          field: 'action',
        }
      ],
    }
  },
  methods:{

    async deleteItem(id)
    {
      this.loading=true
      try {
        
        await APIRequest.delete(id)
        let brand_models=await APIRequest.list_with_request('brand-models','?user='+this.$route.params.id)
   
        this.brand_models=brand_models[0];





        this.loaded=true
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Le produit a bien été supprimé du catalogue !",
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.loading=false
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }

    },

    async addItem(product_id,product_price)
    {
      this.loading=true
      try {
        
        let Parameters={
          user:{id:this.$route.params.id},
          price:this.price,
          glass:{id:product_id.value}

        }
        await APIRequest.create('brand-models',Parameters)
        let brand_models=await APIRequest.list_with_request('brand-models','?user='+this.$route.params.id)
   
        this.brand_models=brand_models[0];

        this.loaded=true
        this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Le produit a bien été ajouté au catalogue !",
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            
          } catch (error) {
            this.loading=false
            
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
    
  },
  async mounted()
  {
    
    
    let brand_models=await APIRequest.list_with_request('brand-models','?user='+this.$route.params.id)
   
    this.brand_models=brand_models[0];

    let available_glasses_req=await APIRequest.list('glasses')
    let available_glasses=available_glasses_req[0]

    let available_glasses_fin=[]

    for(let i=0;i<available_glasses.length;i++)
    {
      let available=true
      for(let j=0;j<this.brand_models.length;j++)
      {
        if(this.brand_models[j].glass.id==available_glasses[i].id)
        {
          available=false

        }   
      }
      if(available==true)
      {
        available_glasses_fin.push(available_glasses[i])
      }
    }

    this.productChoices=Utils.formatSelect(available_glasses_fin,'name','id')

    this.loaded=true
    this.loading=false
  },
  
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>